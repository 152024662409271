var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" VIIIDX ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Sign up to VIII and start using today ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"name":"register-name","placeholder":"John Doe"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"phone","state":errors.length > 0 ? false:null,"name":"phone","placeholder":"604.000.0000"},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.data.phone, 'phone')}},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Website","label-for":"website"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"website","state":errors.length > 0 ? false:null,"name":"website"},model:{value:(_vm.data.website),callback:function ($$v) {_vm.$set(_vm.data, "website", $$v)},expression:"data.website"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Agent ID","label-for":"agent-id"}},[_c('validation-provider',{attrs:{"name":"Agent ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"agent-id","state":errors.length > 0 ? false:null,"name":"agent-id"},model:{value:(_vm.data.agent_mls_loginName),callback:function ($$v) {_vm.$set(_vm.data, "agent_mls_loginName", $$v)},expression:"data.agent_mls_loginName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Member Type","label-for":"member-type"}},[_c('validation-provider',{attrs:{"name":"Member Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"member-type","options":_vm.memberTypeOptions,"name":"member-type","clearable":false,"searchable":false,"label":"text","reduce":function (memberType) { return memberType.value; }},model:{value:(_vm.data.memberType),callback:function ($$v) {_vm.$set(_vm.data, "memberType", $$v)},expression:"data.memberType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"role","options":_vm.roleOptions,"name":"role","clearable":false,"searchable":false,"label":"text","reduce":function (role) { return role.value; }},model:{value:(_vm.data.role),callback:function ($$v) {_vm.$set(_vm.data, "role", $$v)},expression:"data.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isNoOffice)?_c('div',{staticClass:"autoCompleteOfficeContainer"},[_c('b-form-group',{attrs:{"label":"Office Name","label-for":"office-name"}},[_c('validation-provider',{attrs:{"name":"Office Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"get-option-label":function (option) { return option.officeName; },"options":_vm.officeOptions,"filterable":false,"taggable":""},on:{"search":_vm.onSearch,"input":_vm.setOffice},scopedSlots:_vm._u([{key:"option",fn:function(officeOptions){return (Object.keys(_vm.office).length > 0)?[_c('div',{staticClass:"d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName))]),(officeOptions.officeShortName && officeOptions.officePhone)?_c('p',[_vm._v(" "+_vm._s(officeOptions.officeShortName)+" | "+_vm._s(officeOptions.officePhone)+" ")]):_vm._e()])]:undefined}},{key:"selected-option",fn:function(officeOptions){return (Object.keys(_vm.office).length > 0)?[_c('div',{staticClass:"selected d-center"},[_c('p',[_vm._v(_vm._s(officeOptions.officeName))]),(Object.keys(officeOptions).length && officeOptions.officeShortName && officeOptions.officePhone)?_c('p',[_vm._v(" "+_vm._s(officeOptions.officeShortName)+" | "+_vm._s(officeOptions.officePhone)+" ")]):_vm._e()])]:undefined}}],null,true),model:{value:(_vm.office),callback:function ($$v) {_vm.office=$$v},expression:"office"}},[_c('template',{slot:"no-options"},[(_vm.didUserType)?_c('span',[_vm._v(" No results ")]):_c('span',[_vm._v(" type to search for offices ")])])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isShowOfficeID())?_c('b-form-group',{attrs:{"label":"Office ID","label-for":"office-id"}},[_c('validation-provider',{attrs:{"name":"Office ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-id","state":errors.length > 0 ? false:null,"name":"office-id","readonly":""},model:{value:(_vm.data.agent_office_loginName),callback:function ($$v) {_vm.$set(_vm.data, "agent_office_loginName", $$v)},expression:"data.agent_office_loginName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Office Phone","label-for":"office-phone"}},[_c('validation-provider',{attrs:{"name":"Office Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-phone","state":errors.length > 0 ? false:null,"name":"office-phone","readonly":_vm.isPhoneReadOnly()},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.data.officeNumber, 'office-phone')}},model:{value:(_vm.data.officeNumber),callback:function ($$v) {_vm.$set(_vm.data, "officeNumber", $$v)},expression:"data.officeNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Office Fax","label-for":"office-fax"}},[_c('validation-provider',{attrs:{"name":"Office Fax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"office-fax","state":errors.length > 0 ? false:null,"name":"office-fax","readonly":_vm.isFaxReadOnly()},on:{"input":function($event){return _vm.formatPhoneNumber(_vm.data.officeFax, 'office-fax')}},model:{value:(_vm.data.officeFax),callback:function ($$v) {_vm.$set(_vm.data, "officeFax", $$v)},expression:"data.officeFax"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('password')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password Confirm","label-for":"password-confirm"}},[_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"required|password:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password-confirm","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password-confirm","placeholder":"············"},model:{value:(_vm.data.password_confirmation),callback:function ($$v) {_vm.$set(_vm.data, "password_confirmation", $$v)},expression:"data.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility('password')}}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),(_vm.errorMsg)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Sign in instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }